import React, { useState, useEffect, useRef } from 'react';
import api from "../../services/api";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import ProductCard from "../../components/ProductCard";
import Footer from "../../components/Footer";

import { ContainerInfoText, Title } from "./style.js";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const TermsUse = () => {

  document.title = "Bélica Militar - Termos de Uso"

  return (
    <>
      <Header />
      <ContainerInfoText>
        <Container className="conShadown">
          <Title>Termos de Uso</Title>
          <br />
          <p>Bélica, dedicação alimentada pelo dever!</p>
            <p>A Bélica Militar, com sede na Rua Fritz Lorenz, 2071, Bairro Industrial, em Timbó, Santa Catarina, informa que o acesso do usuário a este site e a utilização do mesmo ficam sujeitos aos termos e condições de acordo com a legislação vigente. No que diz respeito aos direitos autorais e propriedade intelectual, os textos, fotografias, imagens e logomarcas presentes no site se encontram protegidos por direitos autorais ou outros direitos de propriedade intelectual, ou seja, devidamente protegidos por meio de registro e contrato firmado entre as partes. Sendo assim, fica expressamente proibida a cópia, reprodução ou modificação destas imagens para uso comercial.</p>
            <br/>
            <p>Ao acessar o presente site, o usuário fica ciente que a utilização indevida das informações aqui contidas estará sujeito às penalidades previstas na lei.</p>
            <br/>
            <p>Ao efetuar o pedido neste site, o cliente concorda com todos os termos aqui presentes automaticamente ao finalizar o pedido.</p>
          <br />
        </Container>
      </ContainerInfoText>
      <Footer />
    </>
  );
}

export default TermsUse;
