import styled from 'styled-components';

export const Card = styled.div`
    transition: 0.5s;
    margin-bottom: 50px;
    padding: 10px;;
    cursor: pointer;
    min-height: 350px;
    padding: 15px;
    
    :hover {
        transition: 0.5s;
        box-shadow: 0px 0px 10px 0px #ccc;
        border-radius: 10px;    
    }

    .imgsCard {
        text-align: center;
        height: 200px;

        img {
            width: 100%;
            max-width: 200px;
        }
    }

    .detailsCard {
            margin-top: 20px;

            h1 {
                font-size: 18px;
                font-weight: 600;
                height: 90px;
            }

            h2 {
                margin-top: -30px;
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 10px;
            }


            p {
                margin: 0px;
                font-size: 12px;
            }
   }

   .promoCard {
        width: 100%;
        display: flex;
        justify-content: right;

       .promo {
            background: #e40606;
            width: 65px;
            padding: 10px;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            border-radius: 50%;
            height: 65px;
            text-align: center;
            margin-top: 0px;
            position: absolute; 
            
            p {
                font-size: 14px;
                color: #fff;
                font-weight: 700;
                padding: 10px;
            }
        }
    }

   @media (max-width: 600px) {
        min-height: 300px;

        h1 {
            text-align: center;
            
        }

        .detailsCard h2{
            margin-top: -35px;
            text-align: center;
            font-size: 20px;
            margin-bottom: 60px;
        }
   }
`;

