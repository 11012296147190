import styled from "styled-components"
import { colors } from "./../../globalStyle";

export const ContainerHeader = styled.div`
    background-color: ${colors.background1};
    background-position: top center;
    background-repeat: repeat;

    .social-media {
        padding: 7px 0px 7px 0px;
        color: #fff;

        span {
            font-size: 20px;
            margin-right: 20px;
        }

        .contact-list {
            opacity: 0;
            text-align: right;

            a {
                color: #fff;
                text-decoration: none;
                padding: 10px 0px 0px 25px;
                font-size: 13px;
                border 
            }

            svg {
                margin-right: 3px;
            }

        }

        a {
            color: #fff;
        }

    }

    .options-list-mobile {
        display: none;
    }

    .menu-container {
        background-color: #fff;
        padding: 15px;

        img {
            width: 200px;
            max-width: 100%;
        }

        .accountOptions {
            svg {
                margin-right: 3px;
            }

            a {
                font-size: 17px;
            }
        }

        .accountOptions div {
            text-align: center;
        }

        .search {
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            padding: 5px;
            align-items: center;
            margin-top: 30px;

            input {
                width: 100%;
                padding: 5px;
                border: 1px solid #ccc;
                border-radius: 5px;
            }

            input :focus {
                outline: none;
                border: none;
                border: 1px solid #ccc;
            }

            svg {
                padding-left: 10px;
                font-size: 30px;
                cursor: pointer;
            }
        }

        .cartMenu {
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            padding: 5px;
            align-items: center;
            margin-top: 30px;
        }
    }

    .menu-groups {
        margin-top: 20px;
        padding-right: 0px;

        .navbar {
            background-color: #00000017 !important;
            border-radius: 5px;
            padding: 2px 0px 2px 0px;
        }
        
        .item-menu {
            min-width: 150px !important;
            color: #444444 !important;
            font-size: 18px !important;
                
        }
    
    }

    @media (max-width: 600px) {
        .contact-list { 
            display: none;
        }

        .menu-container #logoTop {
            text-align: center;
            margin: 10px 0px 5px 0px;

            img {
                width: 150px;
            }
        }

        .menu-container p {
             display: none;
        }

        .social-media .medias {
            display: none;
        }

        .accountOptions {
            display: none;
        }

        .cartMenu{
            display: none !important;
        }

        .options-list-mobile {
            display: block;
            text-align: right;
            padding: 10px;

            a {
                color: #fff;
                margin-left: 40px;
                margin-right: 5px;
            }
        }

        .menu-groups {
            .navbar {
                padding: 7px 0px 7px 0px;
            }
        }
    }
`;

