import styled from "styled-components"
import { colors } from "../../globalStyle";

export const ContainerProduct = styled.div`
    background-color: ${colors.background2};
    background-position: top center;
    background-repeat: repeat;
    color: #ccc;

    .container {
        background-color: #fff;
        padding: 15px;
    }

    padding-bottom: 60px;

    .price {
        margin-top: 20px;
        font-weight: 600;
        font-size: 20px;
        color: #202020;
    }

    .product-details {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .carousel {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .arrow {
        position: absolute;
        font-size: 2rem;
        cursor: pointer;
        z-index: 10;
    }

    .left {
        left: 0;
    }

    .right {
        right: 0;
    }

    .active-image {
        width: 100%;
        max-width: 500px;
        max-height: 500px;
        padding: 20px;
    }

    .thumbnails {
        margin-top: 1rem;
        width: 100%;
        
        scrollbar-width: auto;
        ::-webkit-scrollbar {
            width: 14px;
        }

        ::-webkit-scrollbar-track {
            background: #ffffff;
        }

        ::-webkit-scrollbar-thumb {
            background-color: #cccccc;
            border-radius: 10px;
            border: 3px solid #ffffff;
        }
    }

    .thumbnail {
        width: 60px;
        height: auto;
        cursor: pointer;
        margin: 0 0.5rem;
        opacity: 0.6;
    }

    .thumbnail.active {
        opacity: 1;
        border: 2px solid #333;
    }

    .zoom {
        transition: transform 0.5s ease;
    }
      
    .zoom:hover {
        transform: scale(1.2);
    }

      
    .selectSizeColors {
        margin-top: 30px;
      
        h3 {
            padding-top: 0PX;
            margin-top: 10px;
            font-weight: 550;
            font-size: 20px;
            color: #202020;
        }
      
        .tableColorSize{
            display: flex;
            align-items: end;
      
          .table{
              overflow-x: scroll;
            }
      
          .arrow-indication {
              margin-right: -16%;
              color: #951313;
              font-size: 85px;
              font-weight: bold;
            }      
        }
      
      table td {
          font-size: 15px;
        }

      }

      @media (max-width: 1300px) {
            .arrow-indication {
                margin-right: -16% !important;
                font-size: 70px !important;
            }
      }

      @media (max-width: 600px) {
        .tableColorSize{
            display: block !important;
            .arrow-indication {
                margin-top: -2% !important;
                font-size: 50px !important;
                margin-left: 80%;
            }
        }
      }
      
    .optValue {
        display: flex;
        align-items: center;
        justify-content: center;

        input {
            width: 40px;
            border: none;
            border-bottom: 1px solid #000;
            background: none;
            text-align: center;
            margin: 0px 3px 0px 3px;
        }

        svg {
            cursor: pointer;
        }
    }

    .detailsProduct {
        margin-top: 20px;
        margin-bottom: 20px;
        box-shadow: 0px 0px 10px 0px;
        border-radius: 5px;
        padding: 10px;
    
    }      

    .handedness {
        margin-top: 30px;
        p {
           font-weight: 600; 
        }
    }

    .handedness-square {
        width: 30%;
        height: 50px;
        border: 1px solid #ccc;
        display: inline-block;
        text-align: center;
        line-height: 50px;
        cursor: pointer;
        margin-right: 10px;
        color: #202020;
        font-weight: bold;
      }
      
      .handedness-square-selected {
        background-color: #951313;
        color: white;
      }
      

`;

export const Title = styled.h1`
    padding-top: 0PX;
    margin-top: 10px;
    font-weight: 550;
    font-size: 24px;
    color: #202020;
    padding-bottom: 10px;
    border-bottom: 1px solid;
`;

export const ButtonBuy = styled.button`
    color: #fff;
    background: #00a712;
    border: none;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    box-shadow: 0px 0px 10px 2px #ccc;
    margin-top: 20px;
    font-size: 20px;
`;
