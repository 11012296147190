import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Product from './pages/Product';
import Account from './pages/Account';
import SearchProduct from './pages/SearchProduct';
import SearchGroup from './pages/SearchGroup';
import Cart from './pages/Cart';
import Address from './pages/Address';
import Checkout from './pages/Checkout';
import Orders from './pages/Orders';
import PrivacyPolicy from './pages/PrivacyPolicy';
import AboutUs from './pages/AboutUs';
import TermsUse from './pages/TermsUse';
import ExchangesReturn from './pages/ExchangesReturn';
import ShippingDeliveries from './pages/ShippingDeliveries';
import PaymentMethods from './pages/PaymentMethods';
import NotFound from './pages/NotFound';
import { CartProvider } from './utils/CartContext';

import { GlobalStyle, colors } from "./globalStyle";

function App() {
  return (
    <CartProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={ <Home/> }/>
          <Route path='/product/:idDM005/:description' element={ <Product/> }/>
          <Route path='/account' element={ <Account/> }/>
          <Route path='/buscar/:search' element={ <SearchProduct/> }/>
          <Route path='/group/:id/:search' element={ <SearchGroup/> }/>
          <Route path='/cart' element={ <Cart/> }/>
          <Route path='/address' element={ <Address/> }/>
          <Route path='/checkout' element={ <Checkout/> }/>
          <Route path='/orders' element={ <Orders/> }/>
          <Route path='/privacy-policy' element={ <PrivacyPolicy/> }/>
          <Route path='/about-us' element={ <AboutUs/> }/>
          <Route path='/terms-use' element={<TermsUse/>}/>
          <Route path='/exchanges-return' element={<ExchangesReturn/>}/>
          <Route path='/shipping-deliveries' element={<ShippingDeliveries/>}/>
          <Route path='/payment-methods' element={<PaymentMethods/>}/>
          
          <Route path="*" element={ <NotFound/> } />
        </Routes>
      </BrowserRouter>
    </CartProvider>
  );
}

export default App;

