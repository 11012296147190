import styled from "styled-components"
import { colors } from "../../globalStyle";
import FooterBg from '../../imgs/geral/padrao-rodape.png';

export const ContainerFooterDetails = styled.div`
    min-height: 100px;
    background-image: url(${FooterBg});
    background-position: top center;
    background-repeat: repeat;
    background-color: ${colors.background1};

    .container-fluid, .container {
        color: #fff;
        padding: 50px;
    }

    h2 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 30px;
    }

    p {
        color: #fff;
    }

    a {
        color: #fff;
    }

    p, a {
        font-size: 13px;
    }

    a:hover {
        color: #fff !important;
    }

    p {
        margin-bottom: 5px;
    }

    .copy{
        padding: 10px;
        font-size: 15px;
        text-align: center;
    }

    @media (max-width: 600px) {
        padding: 20px;

        .container-fluid, .container {
            padding-top: 10px;
            padding: 10px;
        }

        h2 {
            margin-bottom: 10px;
            margin-top: 30px;
        }
    }
`;

export const ContainerFooterPayment = styled.div`
    min-height: 100px;
    background-image: url(${FooterBg});
    background-position: top center;
    background-repeat: repeat;
    background-color: #fff;

    .container-fluid, .container {
            padding: 50px;
    }

    h2 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 30px;
    }

    p, a {
        font-size: 13px;
    }

    a:hover {
        color: #fff !important;
    }

    p {
        margin-bottom: 5px;
    }

    #footerPaymentList p{
        font-size: 15px;
        font-weight: bold;
    }

    #social-footer {
        
        span {
            margin: 20px;
        }

        svg {
            font-size: 25px;
        }

        a:hover {
            color: #212529 !important;
        }
    }

    .col-sm-6{
        text-align: center;
    }


    @media (max-width: 600px) {
        padding: 20px;

        .container-fluid, .container {
            padding-top: 10px;
        }

        h2 {
            margin-bottom: 10px;
            margin-top: 30px;
        }
    }

`;

export const ContainerCR = styled.div`
    text-align: center;
    padding: 20px;
    img {
        width: 200px;
    }
`;

export const PrivacyPopup = styled.div`
    position: fixed;
    bottom: 15px;
    width: 80%;
    padding: 1em;
    background: #fff;
    text-align: center;
    margin: 0px 10% 0px 10%;
    box-shadow: 0px 0px 10px #918b8b;
    border-radius: 10px;
    font-size: 17px;

    @media (max-width: 600px) {
        font-size: 13px !important;
    }

    button {
        color: #00a712;
        background: #fff;
        margin-top: 20px;
        font-size: 20px;
        border: 20px;
    }
}
`