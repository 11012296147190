import React, { useState, useEffect, useRef } from 'react';
import api from "../../services/api";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import ProductCard from "../../components/ProductCard";
import Footer from "../../components/Footer";

import { ContainerInfoText, Title } from "./style.js";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ShippingDeliveries = () => {

  document.title = "Bélica Militar - Informações de Envio e Entregas"

  return (
    <>
      <Header />
      <ContainerInfoText>
        <Container className="conShadown">
          <Title>Informações de Envio e Entregas</Title>
          <br />
          <p>Modalidades de Envio/Frete</p>

          <p>Os envios dos nossos produtos s&atilde;o realizados pelos Correios (Pac/Sedex) ou Transportadoras, conforme escolha do cliente, mediante disponibilidades de ambos (Correios e Transportadora) para o CEP informado.</p>

          <p> </p>

          <p>Calculando o Envio/Frete</p>

          <p>Voc&ecirc; poder&aacute; calcular o envio/frete dos seus equipamentos na tela do &quot;Meu Carrinho&quot;, digitando o CEP do endere&ccedil;o de entrega no campo &quot;Calcule o Frete&quot;. Nas compras de 1 (um) &uacute;nico equipamento, voc&ecirc; poder&aacute; calcular o frente diretamente na tela do produto, no campo &quot;Calcule o Frete&quot;.</p>

          <p> </p>

          <p>Escolha da Modalidade de Envio/Frete</p>

          <p>A escolha da modalidade do envio/frete do seu pedido, ap&oacute;s o c&aacute;lculo de envio/frete poder&aacute; ser feita apenas na tela do &quot;Meu Carrinho&quot;.</p>

          <p> </p>

          <p>Envio do Pedido</p>

          <p>O seu pedido ser&aacute; enviado em at&eacute; 3 (tr&ecirc;s) dias &uacute;teis ap&oacute;s a confirma&ccedil;&atilde;o de pagamento via Boleto (para mais informa&ccedil;&otilde;es confira nossa pol&iacute;tica de Formas de Pagamento), e em at&eacute; 2 (dois) dias &uacute;teis para compras no cart&atilde;o. O envio do seu pedido &eacute; realizado por n&oacute;s para os Correios ou Transportadora, de segunda-feira &agrave; sexta-feira, entre 07h30 e 12h00 - 13h10 e 17h30.</p>

          <p> </p>

          <p>Rastreamento do Pedido</p>

          <p>Ap&oacute;s o envio do seu pedido, voc&ecirc; poder&aacute; acompanhar o rastraemento atrav&eacute;s das informa&ccedil;&otilde;es e instru&ccedil;&otilde;es enviadas diretamente para seu e-mail.</p>

          <p>&bull; Lembre-se: Enviaremos as informa&ccedil;&otilde;es e instru&ccedil;&otilde;es para o e-mail que voc&ecirc; utilizou para criar seu cadastro em nossa loja virtual (www.coldresbelica.com.br).</p>

          <p> </p>

          <p>Entrega do seu Pedido</p>

          <p>Correios  As entregas s&atilde;o realizadas em dias &uacute;teis, de segunda-feira &agrave; sexta-feira, dentro do hor&aacute;rio de atendimento dos Correios, em todos os munic&iacute;pios brasileiros. A entrega &eacute; domicilar sendo necess&aacute;rio ter algu&eacute;m no local de entrega para receber o pedido e assinar o comprovante de entrega, exceto em munic&iacute;pios onde &eacute; necess&aacute;rio que voc&ecirc; v&aacute; at&eacute; o Centro de Atendimento do Correio para retirar o seu pedido.</p>

          <p>Ser&atilde;o realizadas 2 (duas) tentativas de entrega, onde a 1&ordf; tentativa ser&aacute; realizada logo ap&oacute;s a chegada do seu pedido na unidade de distribui&ccedil;&atilde;o (consulte o rastreamento), e a 2&ordf; tentativa ser&aacute; realizada no dia seguinte ao da 1&ordf; tentativa.</p>

          <p>Em caso de n&atilde;o ter sido poss&iacute;vel realizar a entrega do seu pedido, por for&ccedil;a de n&atilde;o haver um recebedor, e ou, erros no endere&ccedil;o informado por voc&ecirc;, o pedido ir&aacute; para a Posta Restante da Ag&ecirc;ncia mais pr&oacute;xima do endere&ccedil;o do destinat&aacute;rio (ou endere&ccedil;o informado erroneamente), permanecendo por um prazo de 7 dias corridos. Ap&oacute;s este prazo, o seu pedido ser&aacute; devolvido imediatamente para n&oacute;s.</p>

          <p>&bull; Lembre-se: Em caso de o pedido retornar para n&oacute;s, para realizar um novo envio o cliente ser&aacute; respons&aacute;vel pelo pagamento do envio.</p>

          <p>&bull; Importante: Sempre verifique, antes de finalizar o seu pedido, as informa&ccedil;&otilde;es contidas no endere&ccedil;o de entrega, para garantir que os dados estejam corretos.</p>

          <p>&bull; Lembre-se: N&atilde;o &eacute; poss&iacute;vel agendar a data ou o hor&aacute;rio da entrega.</p>

          <p> Transportadora As entregas s&atilde;o realizadas em dias &uacute;teis, de segunda-feira &agrave; sexta-feira, dentro do hor&aacute;rio de atendimento comercial da sua localidade, em todos os munic&iacute;pios brasileiros. A entrega &eacute; domicilar, sendo necess&aacute;rio ter algu&eacute;m no local da entrega para receber e assinar o comprovante de entrega.</p>

          <p>Em caso de n&atilde;o ter sido poss&iacute;vel realizar a entrega do seu pedido, por for&ccedil;a de n&atilde;o haver um recebedor, e ou, erros no endere&ccedil;o informado por voc&ecirc;, o pedido ir&aacute; para o Centro de Distribui&ccedil;&atilde;o mais pr&oacute;xima do endere&ccedil;o do destinat&aacute;rio (ou endere&ccedil;o informado erroneamente), permanecendo por um prazo de 7 dias corridos, podendo este prazo sofrer uma varia&ccedil;&atilde;o conforme as pol&iacute;ticas de cada Transportadora. Ap&oacute;s este prazo, o seu pedido ser&aacute; devolvido imediatamente para n&oacute;s.</p>

          <p>&bull; Lembre-se: Em caso de o pedido retornar para n&oacute;s, para realizar um novo envio o cliente ser&aacute; respons&aacute;vel pelo pagamento do envio.</p>

          <p>&bull; Importante: Sempre verifique, antes de finalizar o seu pedido, as informa&ccedil;&otilde;es contidas no endere&ccedil;o de entrega, para garantir que os dados estejam corretos.</p>

          <p>&bull; Lembre-se: N&atilde;o &eacute; poss&iacute;vel agendar a data ou o hor&aacute;rio da entrega.</p>

          <p>O prazo de entrega varia de acordo com a regi&atilde;o, onde o prazo de entrega mostrado na consulta &quot;Calcule o Frete&quot; trata-se de uma previs&atilde;o, podendo assim, haver no momento da entrega uma varia&ccedil;&atilde;o do prazo sem aviso pr&eacute;vio.</p>

          <p> </p>

          <p>Maiores Informa&ccedil;&otilde;es</p>

          <p>Em caso de ainda restar d&uacute;vidas sobre Frete, Envio e Entrega, fale conosco atrav&eacute;s de nossos canais de atendimento dispon&iacute;veis aqui em nossa loja virtual.</p>
          <br />
        </Container>
      </ContainerInfoText>
      <Footer />
    </>
  );
}

export default ShippingDeliveries;
