import styled from "styled-components"
import { colors } from "./../../globalStyle";

export const ContainerHome = styled.div`
    background-color: ${colors.background2};
    background-position: top center;
    background-repeat: repeat;

    .container {
        background-color: #fff;
        padding: 15px;
    }

    padding-bottom: 60px;
`;

export const Title = styled.h2`
    padding-top: 20px;
    margin-top: 10px;
    border-top: 2px solid #767676;    
    font-weight: 600;
    font-size: 18px;

`;

