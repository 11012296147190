import { ContainerSelect, Select } from "./style.js";

const SelectDefault = ({children, Margin, Width, Text, Type, Need, ...rest }) => {

  return (
      <>
        <ContainerSelect Width={Width} Margin={Margin}>
          <label>
            {Need ? <span>* </span> : null}
            {Text}
          </label>
          <Select {...rest}> 
            {children}
          </Select>
        
        </ContainerSelect>
      </>
  );
}

export default SelectDefault;