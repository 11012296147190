import React, { useState, useEffect, useRef } from 'react';
import api from "../../services/api";

import {ContainerBanner} from "./style.js";
import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from "react-router-dom";

const Banner = (  ) => {

  const [bannerList, setBannerList] = useState([]);

  useEffect(() => {  
      api
      .get("get_all_banners")
      .then((response) => {
        setBannerList(response.data)
      })
      .catch((err) => {
        console.log(err)
      });
    }, []);

  return (
    <>
      <ContainerBanner>
        <Container className="conShadown">
          <Carousel>
            {bannerList.map((banner, index) => (
              <Carousel.Item key={index}>
                <Link to={banner.DS_LINK}>
                  <img
                    className="d-block w-100"
                    src={banner.DS_PATH}
                    alt={banner.NM_BANNER}
                  />
                </Link>
              </Carousel.Item>
            ))}
          </Carousel>
        </Container>
      </ContainerBanner>
    </>
  );
}

export default Banner;
