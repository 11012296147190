/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import api from "../../services/api";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { ContainerCheckout, Title } from "./style.js";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import SelectDefault from "../../components/SelectDefault";
import { verifyLogin } from "./../../utils/verifyLogin";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

import Table from 'react-bootstrap/Table';

import ButtonDefault from "../../components/ButtonDefault";

import { Link } from "react-router-dom";

import { formatCurrencyBRL } from "./../../utils/formatCurrencyBRL";
import { getDevice } from "./../../utils/getDevice";

import { useCart } from './../../utils/CartContext';

import ModalAddAddress from './../../components/ModalAddAddress';

const Checkout = () => {

  document.title = "Bélica Militar - Finalizar Pedido"

  const { setCartLength } = useCart();

  const navigate = useNavigate();

  const [isSending, setIsSending] = useState(false);
  const [idDM001, setIdDM001] = useState(0);
  const [mailClient, setMailClient] = useState(0);
  const [cdClient, setCdClient] = useState(0);
  const [addressSelected, setAddressSelected] = useState();
  const [paymentSelected, setPaymentSelected] = useState();
  const [dispatchSelected, setDispatchSelected] = useState();
  const [addressList, setAddressList] = useState([]);
  const [paymentList, setPaymentList] = useState([]);
  const [dispatchList, setDispatchList] = useState([]);

  const [prodListCart, setProdListCart] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [sizeList, setSizeList] = useState([]);
  const [vlMinOrder, setVlMinOrder] = useState(0);
  const [observation, setObservation] = useState('');
  const [paymentError, setPaymentError] = useState(false);
  const [dispatchError, setDispatchError] = useState(false);
  const [handleModalAddAddress, setHandleModalAddAddress] = useState(false);

  useEffect(() => {
    if (!verifyLogin()) {
      navigate(`/`);
    }

    const lsClient = JSON.parse(localStorage.getItem('userDataLogin'))
    if (lsClient.ID_DM001) {
      setIdDM001(lsClient.ID_DM001)
      setCdClient(lsClient.CD_CLIENTE)
      setMailClient(lsClient.DS_EMAIL)
    } else {
      navigate(`/`);
    }

  }, []);

  useEffect(() => {
    const dataOrdersFiltger = {
      "ID_DM001": idDM001
    }

    if (idDM001) {
      api
        .post("get_orders_filter", dataOrdersFiltger)
        .then((response) => {
          const filteredResponseData = response.data.filter((item) => item.IN_SITUACAO !== "3");

          let dataFilterParm = {}

          if (filteredResponseData.length < 2) {
            dataFilterParm = { 'DS_KEY_PARAMETRO': 'VL_PEDIDO_MINIMO_1' };
          } else {
            dataFilterParm = { 'DS_KEY_PARAMETRO': 'VL_PEDIDO_MINIMO_2' };
          }

          api
            .post("filter_parms", dataFilterParm)
            .then((response) => {
              if (response.data[0]["VL_PEDIDO_MINIMO_1"]) {
                setVlMinOrder(response.data[0]["DS_VALOR_PARAMETRO"])
              } else {
                setVlMinOrder(response.data[0]["DS_VALOR_PARAMETRO"])
              }
            })
            .catch((err) => {
              console.log(err)
            });
        })

        .catch((err) => {
          console.log(err)
        });

      api
        .get(`get_all_payments`)
        .then((response) => {
          setPaymentList(response.data)
        })
        .catch((err) => {
          toast.error('Erro, tente novamente mais tarde!')
          console.log(err)
        });

      api
        .get(`get_all_dispatchs`)
        .then((response) => {
          setDispatchList(response.data)
        })
        .catch((err) => {
          toast.error('Erro, tente novamente mais tarde!')
          console.log(err)
        });

      getAddressClient()
    }
  }, [idDM001]);

  const getAddressClient = () => {
    api
      .get(`get_all_address/${idDM001}`)
      .then((response) => {
        let lastAddressWithInPadraoSIndex = null;
  
        const updatedAddresses = response.data.map((address, index) => {
          if (address.IN_PADRAO === "S") {
            lastAddressWithInPadraoSIndex = index;
          }
          return { ...address, IN_PADRAO: "N" };
        });
  
        if (lastAddressWithInPadraoSIndex !== null) {
          updatedAddresses[lastAddressWithInPadraoSIndex].IN_PADRAO = "S";
        }
  
        setAddressList(updatedAddresses);
      })
      .catch((err) => {
        toast.error('Erro, tente novamente mais tarde!');
        console.error(err);
      });
  };
  

  useEffect(() => {
    getAddressClient()
  }, [idDM001]);

  useEffect(() => {
    if (!verifyLogin()) {
      navigate(`/`);
    }

    const prodList = JSON.parse(localStorage.getItem('productCart'));
    if (prodList) {
      setProdListCart(prodList)
    }

  }, []);

  useEffect(() => {
    api
      .get("get_all_sizes")
      .then((response) => {
        setSizeList(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    api
      .get("get_all_colors")
      .then((response) => {
        setColorList(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const findSizeById = (id) => {
    const size = sizeList.find((size) => size.ID_DM004 === parseInt(id));
    return size ? size.SG_TAMANHO : "";
  };

  const findColorById = (id) => {
    const color = colorList.find((color) => color.ID_DM003 === parseInt(id));
    return color ? color.NM_COR : "";
  };

  const calculateTotalPrice = (quantities, price) => {
    const totalQuantities = Object.values(quantities).reduce((acc, curr) => acc + curr, 0);
    return totalQuantities * price;
  };

  const orderCartFormatter = () => {
    const remodeledProdListCart = prodListCart.map((product) => {
      const { quantities, ...rest } = product;
      const newQuantities = Object.entries(quantities).reduce((acc, [key, value]) => {
        const [colorId, sizeId] = key.split('-');
        if (!acc[colorId]) {
          acc[colorId] = {};
        }
        if (!acc[colorId][sizeId]) {
          acc[colorId][sizeId] = {};
        }
        acc[colorId][sizeId][product.laterality] = value;
        return acc;
      }, {});
      return { ...rest, quantities: newQuantities };
    });

    const groupedProducts = remodeledProdListCart.reduce((acc, product) => {
      const { id } = product;
      if (!acc[id]) {
        acc[id] = { ...product };
      } else {
        Object.keys(product.quantities).forEach((colorId) => {
          if (!acc[id].quantities[colorId]) {
            acc[id].quantities[colorId] = { ...product.quantities[colorId] };
          } else {
            Object.keys(product.quantities[colorId]).forEach((sizeId) => {
              if (!acc[id].quantities[colorId][sizeId]) {
                acc[id].quantities[colorId][sizeId] = { ...product.quantities[colorId][sizeId] };
              } else {
                Object.keys(product.quantities[colorId][sizeId]).forEach((laterality) => {
                  if (!acc[id].quantities[colorId][sizeId][laterality]) {
                    acc[id].quantities[colorId][sizeId][laterality] = product.quantities[colorId][sizeId][laterality];
                  } else {
                    acc[id].quantities[colorId][sizeId][laterality] += product.quantities[colorId][sizeId][laterality];
                  }
                });
              }
            });
          }
        });
      }
      return acc;
    }, {});

    return Object.values(groupedProducts);
  };

  const calculateTotalQuantity = (quantities) => {
    const totalQuantities = Object.values(quantities).reduce((acc, curr) => acc + curr, 0);
    return totalQuantities;
  };

  const sendOrder = () => {
    let error = false;
    if (!addressSelected) {
      toast.warning('Selecione um endereço para entrega!');
      error = true;
    }

    if (!paymentSelected) {
      toast.warning('Selecione a forma de pagamento!');
      setPaymentError(true);
      error = true;
    } else {
      setPaymentError(false);
    }

    if (!dispatchSelected) {
      toast.warning('Selecione a forma de envio!');
      setDispatchError(true);
      error = true;
    } else {
      setDispatchError(false);
    }

    if (error) return;

    if (addressSelected) {
      if (paymentSelected && dispatchSelected) {
        const DataOrder = {
          "clientId": idDM001,
          "cdClient": cdClient,
          "addressId": addressSelected,
          "mailClient": mailClient,
          'DS_OBSERVACAO': observation,
          'DS_FORMA_PAGAMENTO': paymentSelected,
          'DS_FORMA_ENVIO': dispatchSelected,
          "NM_DISPOSITIVO": getDevice(),
          "products": orderCartFormatter()
        }
        setIsSending(true);
          api
            .post("create_order", DataOrder)
            .then((response) => {
              if (response.data.success) {
                localStorage.removeItem('productCart')
                setCartLength(0)
                setProdListCart([])
                navigate('/orders')
              } else {
                toast.warning(response.data.message)
              }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              isSending(false);
            });
      } else {
        toast.warning('Selecione a forma de pagamento e forma de envio!')
      }
    } else {
      toast.warning('Selecione um endereço para entrega!')
    }
  }

  useEffect(() => {
    const defaultAddress = addressList.find(address => address.IN_PADRAO === 'S');
    if (defaultAddress) setAddressSelected(defaultAddress.ID_DM002);
  }, [addressList]);

  const grandTotal = prodListCart.reduce((acc, prod) => acc + calculateTotalPrice(prod.quantities, prod.price), 0);

  useEffect(() => {
    if (vlMinOrder > 0 && vlMinOrder > grandTotal) {
      toast.warning(`Pedido mínimo de: ${formatCurrencyBRL(vlMinOrder)}`);
      toast.warning(`Redirecionando para o carrinho...`);
      setTimeout(() => {
        navigate('/cart');
      }, 5000);
    }
  }, [vlMinOrder, grandTotal]);

  const addNewAddressOpen = useCallback(() => {
    setHandleModalAddAddress(true);
  },
    [handleModalAddAddress]
  );

  const onCloseModalAddAddress = () => {
    setHandleModalAddAddress(false);
  };

  const addNewAddress = (address) => {
    api
      .post("create_client_address", address)
      .then((response) => {
        if (response.data) {
          if (response.data.success) {
            toast.success(response.data.message)
            setHandleModalAddAddress(false);
            getAddressClient();
          } else {
            toast.warning(response.data.message)
          }
        } else {
          toast.error(response.data.message)
        }
      })
      .catch((err) => {
        toast.error('Erro, tente novamente mais tarde!')
        console.log(err)
      });
  }

  return (
    <>

      <Header />
      <ContainerCheckout>
        <Container className="conShadown">
          <Title>Finalizar Pedido</Title>
          <br />
          <Row>
            <Col sm={10}>
              <SelectDefault Text="Selecione o Endereço:" Width={'100%'} value={addressSelected} onChange={e => setAddressSelected(e.target.value)}>
                <option value="">Selecionar</option>
                {addressList.map((address, index) => {
                  return (
                    <option value={address.ID_DM002}>
                      {address.NM_ENDERECO}
                    </option>
                  )
                })}
              </SelectDefault>
            </Col>
            <Col sm={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
              <strong><Link to="" onClick={addNewAddressOpen}>+ Novo Endereço</Link></strong>
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={6}>
              <SelectDefault Text="Forma de Pagamento:" style={paymentError ? { borderColor: 'red', color: 'red' } : {}} Width={'100%'} value={paymentSelected} onChange={e => setPaymentSelected(e.target.value)} Need={true}>
                <option value="">Selecionar</option>
                {paymentList.map((payment, index) => {
                  return (
                    <option value={payment.DS_FORMA_PAGAMENTO}>
                      {payment.DS_FORMA_PAGAMENTO}
                    </option>
                  )
                })}
              </SelectDefault>
            </Col>
            <Col sm={6}>
              <SelectDefault Text="Forma de Envio:" style={dispatchError ? { borderColor: 'red', color: 'red' } : {}} Width={'100%'} value={dispatchSelected} onChange={e => setDispatchSelected(e.target.value)} Need={true}>
                <option value="">Selecionar</option>
                {dispatchList.map((dispatch, index) => {
                  return (
                    <option value={dispatch.DS_FORMA_ENVIO}>
                      {dispatch.DS_FORMA_ENVIO}
                    </option>
                  )
                })}
              </SelectDefault>
            </Col>
          </Row>
          {prodListCart.length > 0 ?
            <>
              <Row className='productListCheckout'>
                <Col sm={12}>
                  <Row className='cabecalhoCart'>
                    <Col sm={12} md={3} className="cellCart">
                      <p>Nome do Produto</p>
                    </Col>
                    <Col sm={12} md={4} className="cellCart">
                      <p>Variações</p>
                    </Col>
                    <Col sm={12} md={1} className="cellCart" style={{ justifyContent: 'right' }}>
                      <p>Quantidade</p>
                    </Col>
                    <Col sm={12} md={2} className="cellCart" style={{ justifyContent: 'right' }}>
                      <p>Preço Unitário</p>
                    </Col>
                    <Col sm={12} md={2} className="cellCart" style={{ justifyContent: 'right' }}>
                      <p>Total</p>
                    </Col>
                  </Row>
                </Col>
                <Col sm={12}>
                  {prodListCart.map((prod, index) => {
                    const totalPrice = calculateTotalPrice(prod.quantities, prod.price);
                    const totalQuantity = calculateTotalQuantity(prod.quantities);
                    return (
                      <Row className='prodCard' key={index}>
                        <Col sm={12} md={3} className="cellCart">
                          <p>{prod.name} - Lado: {prod.laterality === 'C' ? 'Canhoto' : prod.laterality === 'D' ? 'Destro' : 'Neutro'}</p>
                        </Col>
                        <Col sm={12} md={4}>
                          <Table striped bordered hover size="sm">
                            <thead>
                              <tr>
                                <th>Cor</th>
                                <th>Tamanho</th>
                                <th>Quantidade</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.entries(prod.quantities)
                                .map(([key, value]) => {
                                  const [colorId, sizeId] = key.split('-');
                                  const color = findColorById(colorId);
                                  const size = findSizeById(sizeId);
                                  return { color, size, colorId, sizeId, value };
                                })
                                .sort((a, b) => a.color.localeCompare(b.color))
                                .map(({ color, size, colorId, sizeId, value }) => {
                                  return (
                                    <tr key={`${colorId}-${sizeId}`}>
                                      <td>{color}</td>
                                      <td style={{ textAlign: 'center' }}>{size}</td>
                                      <td style={{ textAlign: 'center' }}>
                                        {value}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </Table>
                        </Col>
                        <Col xs={6} md={1} style={{ justifyContent: 'center', fontWeight: '600' }} className="cellCart">
                          <p>{totalQuantity}</p>
                        </Col>
                        <Col xs={6} md={2} style={{ justifyContent: 'right', fontWeight: '600' }} className="cellCart">
                          <p>{formatCurrencyBRL(prod.price)}</p>
                        </Col>
                        <Col xs={6} md={2} style={{ justifyContent: 'right', fontWeight: '600' }} className="cellCart">
                          <p>{formatCurrencyBRL(totalPrice)}</p>
                        </Col>
                        <hr />
                      </Row>
                    );
                  })}
                </Col>
                <Col sm={12}>
                  <span><strong>Observação:</strong></span>
                  <textarea
                    style={{ width: '100%', height: '200px', resize: 'none' }}
                    onChange={(e) => setObservation(e.target.value)}
                  >
                  </textarea>
                </Col>
              </Row>
              <Row className='footerCart'>
                <Col xs={12} md={8} style={{ justifyContent: 'left', fontWeight: '600' }} className="cellCart">
                  <Link to="/cart">
                    <ButtonDefault disabled={isSending} Text={'Voltar'} Width={'150px'} Margin={'0px 20px 0px 0px'} />
                  </Link>
                  {
                    vlMinOrder < grandTotal ?
                      <ButtonDefault 
                      disabled={isSending}
                      Text={
                        isSending
                          ? 'Enviando...'
                          : 'Finalizar Pedido'
                      }
                      Width={'200px'}
                      style={{ background: 'green' }}
                      onClick={sendOrder} />
                      :
                      ''
                  }
                </Col>
                <Col xs={12} md={4}>
                  <p className='totalsCart'>Valor Total: {formatCurrencyBRL(grandTotal)}</p>
                </Col>
              </Row>
            </>
            : ''
          }

        </Container>
      </ContainerCheckout>
      <ToastContainer />
      <ModalAddAddress
        modalStatus={handleModalAddAddress}
        closeModelFunction={onCloseModalAddAddress}
        addNewAddressFunction={addNewAddress}
      />
      <Footer />
    </>
  );
}

export default Checkout;
