import React, { useState, useEffect } from 'react';
import api from "../../services/api";
import { Link } from "react-router-dom";

import { FaYoutube, FaInstagram } from "react-icons/fa";
import americanFlag from '../../imgs/paymentsFlags/americanFlag.png';
import dinnersFlag from '../../imgs/paymentsFlags/dinnersFlag.png';
import eloFlag from '../../imgs/paymentsFlags/eloFlag.png';
import hypercardFlag from '../../imgs/paymentsFlags/hypercardFlag.png';
import mastercardFlag from '../../imgs/paymentsFlags/mastercardFlag.png';
import visaFlag from '../../imgs/paymentsFlags/visaFlag.png';
import boletoFlag from '../../imgs/paymentsFlags/boletoFlag.png';
import mercadoPagoFlag from '../../imgs/paymentsFlags/mercadoPagoFlag.png';
import stamp_encryptssl from '../../imgs/stamps/stamp_encryptssl.png';

import {ContainerFooterDetails, ContainerFooterPayment, ContainerCR, PrivacyPopup} from "./style.js";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import dmLogo from '../../imgs/logos/dm.png';

const Footer = (  ) => {
  
  const [groupListFooter, setGroupListFooter] = useState([]);
  const hasAcceptedPrivacy = localStorage.getItem('hasAcceptedPrivacy');
  const [showPrivacyPopup, setShowPrivacyPopup] = useState(!hasAcceptedPrivacy);
  
  useEffect(() => {
    api
      .get("get_all_groups")
      .then((response) => {
        setGroupListFooter(response.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  

  const handleAcceptPrivacy = () => {
    localStorage.setItem('hasAcceptedPrivacy', 'true');
    setShowPrivacyPopup(false);
  };

  const currentYear = new Date().getFullYear();

  return (
    <>
      <ContainerFooterDetails>
        <Container fluid>
          <Row>
            <Col sm={2}>
              <h2>Categorias</h2>
              {
                groupListFooter.filter((group) => !group.ID_DM013_PAI).map((group, index) => {
                  return (
                    <p key={index}>- <Link to={`/group/${group.ID_DM013}/${group.NM_GRUPO.replace(/\//g, ' | ')}`}>
                      {group.NM_GRUPO}
                    </Link></p>
                  )
                })
              }
            </Col>
            <Col sm={2}>
              <h2>Conteúdo</h2>
              <p>- <Link to="/payment-methods">Formas de Pagamento</Link></p>
              <p>- <Link to="/shipping-deliveries">Informações de Envio e Entregas</Link></p>
              <p>- <Link to="/privacy-policy">Política de Privacidade</Link></p>
              <p>- <Link to="/about-us">Sobre</Link></p>
              <p>- <Link to="/terms-use">Termos de Uso</Link></p>
              <p>- <Link to="/exchanges-return">Troca e Devolução</Link></p>
            </Col>
            <Col sm={2}>
              <h2>Formas de Pagamento</h2>
              <div id="footerPaymentList">
                <p>- Boleto</p>
                <p>- Transferência</p>
                <p>- Depósito</p>
              </div>
            </Col>
            <Col sm={2}>
              <h2>Contato</h2>
              <div id="footerPaymentList">
                <p>- Telefone: (47) 3333-1759</p>
                <p>- Telefone: (47) 3333-0813</p>
                <p>- E-mail: vendas@belicamilitar.com.br</p>
                <p>- Endereço: Rua Fritz Lorenz 2071, Industrial, Timbó - 89120-000 - Santa Catarina - Brasil</p>
              </div>
            </Col>
            <Col sm={4}>
              <h2>Sobre a Loja</h2>
              <p>
              A Bélica Militar é referência em coldres, acessórios e vestuários táticos de Alta Qualidade e Desempenho para Policiais, Militares das Forças Armadas e CACs. Produzimos equipamentos pensados especialmente para acompanhá-los em cada operação. Nossos produtos são resultado de intensa pesquisa de mercado nacional e internacional, sugestões e opiniões de operadores reais. A unidade fabril está sediada em Timbó, Santa Catarina, de onde partimos para atender e suprir todo território nacional.
              </p>
            </Col>
          </Row>
        </Container>
        <p className='copy'>Bélica Militar LTDA - CNPJ: 40.807.222/0001-35 © Todos os direitos reservados. {currentYear}</p>
      </ContainerFooterDetails>
      <ContainerFooterPayment>
        <Container>
          <Row>
            <Col sm={6}>
              <h2>Selos</h2>
              <img src={stamp_encryptssl} alt=""/>
            </Col>
            <Col sm={6}>
              <h2>Redes Sociais</h2>
              <div id="social-footer">
                <Link to="https://www.youtube.com/@BelicaMilitar" target="_blank"><span><FaYoutube/></span></Link>
                <Link to="https://www.instagram.com/belicamilitar/" target="_blank"><span><FaInstagram/></span></Link>
              </div>
            </Col>
          </Row>
        </Container>
      </ContainerFooterPayment>
      <ContainerCR>
        <Container>
          <Link to="https://datamais.com.br" target="_blank"><img src={dmLogo} alt=""/></Link>
        </Container>
      </ContainerCR>
      {showPrivacyPopup && (
        <PrivacyPopup>
          <p>Utilizamos cookies para que você tenha a melhor experiência em nosso site. Para saber mais acesse nossa página de <Link to="/privacy-policy">Política de Privacidade</Link></p>
          <button onClick={handleAcceptPrivacy}>Entendi</button>
        </PrivacyPopup>
      )}
    </>
  );
}

export default Footer;
