import styled from "styled-components"
import { colors } from "./../../globalStyle";

export const ContainerBanner = styled.div`
    background-color: ${colors.background2};   
    
    
    .container {
        background-color: #fff;
    }
`;

