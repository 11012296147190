import styled from "styled-components"
import { colors } from "../../globalStyle";

export const ContainerCart = styled.div`
    background-color: ${colors.background2};
    background-position: top center;
    background-repeat: repeat;

    .container {
        background-color: #fff;
        padding: 15px;
    }

    padding-bottom: 60px;

    .prodCard:not(:first-child){
        margin-top: 25px;
    }

    .cabecalhoCart {
        margin-bottom: 20px;

        p {
            font-weight: 600;
        }
    }

    .cellCart {
        display: flex;
        align-items: center;
    }

    .totalsCart {
        font-weight: 600;
        margin: 10px 0px 10px 0px;
        text-align: right;
        font-size: 17px;
    }

    .footerCart {
        margin-top: 20px;
    }

    @media (max-width: 600px) {
        .cellCart {
            display: block;
        }

        .cabecalhoCart {
            display: none;
        }

        .totalsCart {
            margin-top: 30px;
        }

        .footerCart button {
            font-size: 16px;
            width: 100%;
            margin-top: 20px;
        }

        table {
            margin-top: 20px;
        }
    }
    
`;

export const Title = styled.h2`
    padding-top: 20px;
    margin-top: 10px;
    border-top: 2px solid #767676;    
    font-weight: 600;
    font-size: 20px;

`;

