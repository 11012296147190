import React from 'react';
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { ContainerInfoText, Title } from "./style.js";

import Container from 'react-bootstrap/Container';

const PaymentMethods = () => {

  document.title = "Bélica Militar - Formas de Pagamento"

  return (
    <>
      <Header />
      <ContainerInfoText>
        <Container className="conShadown">
          <Title>Formas de Pagamento</Title>
          <p>Aceitamos as seguintes formas de pagamento: à vista, depósito bancário, transferência online, boleto e à prazo (Mediante cadastro e aprovação de crédito). Somente será liberada a mercadoria após identificação do pagamento.</p>
          
          <p><strong>À vista:</strong> Pagamento à vista é concedido um desconto de 5%. Pedidos com caixas fechadas (maiores quantidades) poderá ser negociado um desconto maior.</p>
          
          <p><strong>Depósito bancário:</strong> O cliente deverá fazer o depósito para os dados bancários informados no orçamento enviado pelo e-mail vendas@belicamilitar.com.br. Após efetuar o pagamento, o cliente deverá enviar um comprovante para agilizar a identificação do pagamento pelo departamento financeiro da Bélica. Para evitar fraudes, o cliente deve realizar o depósito somente nos dados bancários informados no orçamento.</p>
          
          <p><strong>Obs:</strong> Depósitos em cheque geralmente são liberados entre dois a três dias úteis para sua compensação bancária. Não são considerados dias úteis: Sábados, Domingos, feriados municipais, feriados estaduais e feriados nacionais.</p>
          
          <p><strong>Transferência online:</strong> O cliente deverá fazer a transferência para os dados bancários informados no orçamento enviado pelo e-mail vendas@belicamilitar.com.br. Após efetuar a transferência, o cliente deverá enviar um comprovante para agilizar a identificação do pagamento pelo departamento financeiro da Bélica. Para evitar fraudes, o cliente deve realizar a transferência somente para os dados bancários informados no orçamento.</p>
          
          <p><strong>Boleto:</strong> O cliente receberá um boleto no orçamento enviado pelo e-mail vendas@belicamilitar.com.br. Para efetuar o pagamento, o cliente deve imprimir o boleto recebido e pagar em qualquer agência bancária ou pela internet antes do prazo de validade do mesmo. Importante: o prazo de entrega para compras em boleto passa a valer somente após a confirmação do pagamento pela instituição financeira, o que pode levar até 2 (dois) dias úteis. O boleto bancário não é enviado pelos Correios. A data de vencimento do boleto para as compras à vista é de 02 (dois) dias úteis após a realização da compra, após esta data, o documento perde a validade.</p>
          
          <p><strong>Prazo:</strong> O pagamento à prazo pode ser feito à partir da segunda compra (mediante aprovação do financeiro e do cadastro). O valor do limite para as compras a prazo, será liberado conforme histórico de compras anteriores. Boleto mínimo é de R$ 200,00 cada.</p>
          
          <p>Para maiores informações, entre em contato pelo e-mail: vendas@belicamilitar.com.br.</p>
        </Container>
      </ContainerInfoText>
      <Footer />
    </>
  );
}

export default PaymentMethods;
